<template>
  <div>
    <div class="_BoxHeader">
      <span>文件实施类别管理</span>
    </div>
    <div class="PageContain">
      <div class="_SelectHeader">
        <span class="_Title">信息列表</span>
        <div class="_Inp">
          <span>文件名称/编号：</span>
          <el-input
            placeholder="请输入文件名/文件编号"
            v-model="fileVal"
            @input="changInpVal"
          />
        </div>
        <el-button
          type="success"
          icon="el-icon-search"
          @click="searchList('FileAuditTypeNo')"
          >搜索</el-button
        >
      </div>
      <div class="tow-box">
        <div>
          <el-table
            :data="isSearch ? searchLits : fileClassList"
            style="width: 100%"
            :header-cell-style="{ background: '#E2E2E2', color: '#5C5C5C' }"
            stripe
            max-height="elementHeight"
            id="table"
          >
            <el-table-column type="index" label="序号" width="180">
            </el-table-column>
            <el-table-column
              prop="fileAuditTypeNo"
              label="文件编号"
              width="180"
            >
            </el-table-column>
            <el-table-column prop="fileAuditTypeName" label="文件名称">
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <i
                  class="el-icon-delete"
                  @click="deleteItem(scope.row, scope.$index)"
                ></i>
                <i class="el-icon-edit" @click="editItem(scope.row)"></i>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div>
          <p>添加文件</p>
          <div class="_Inp">
            <span>文件编号：</span>
            <el-input placeholder="请输入" v-model="fileNo" />
          </div>
          <div class="_Inp">
            <span>文件名称：</span>
            <el-input placeholder="请输入" v-model="fileName" />
          </div>
          <el-button type="primary" @click="addFileClass">确定</el-button>
          <el-button v-if="!isAdd" @click="cancle">取消</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, post, put, deleteMethod } from '../../api/http'
export default {
  data() {
    return {
      elementHeight: 0,
      functionNanme: '',
      tableData: [
        {
          name: 'xxx文件',
        },
        {
          name: 'xxx文件',
        },
        {
          name: 'xxx文件',
        },
      ],
      value: '',
      isSearch: false,
      fileClassVal: '',
      searchLits: [],
      fileClassList: [],
      fileNo: '',
      fileName: '',
      isAdd: true,
      rowItem: {},
      fileVal: '',
    }
  },
  mounted() {
    this.getfileClassInfo()
    this.getElementHeight()
  },
  methods: {
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById('table').offsetTop + 60 + 52 + 50)
      })
    },
    changInpVal(val) {
      if (val == '') this.isSearch = false
    },
    searchList(key) {
      this.searchLits = []
      this.isSearch = true
      get('/api/FileAuditType/GetByAuditType?' + key + '=' + this.fileVal).then(
        (resp) => {
          if (resp.code == 200) {
            if (resp.data.length == 0 && key == 'FileAuditTypeNo') {
              this.searchList('FileAuditTypeName')
            } else {
              this.searchLits = resp.data
            }
          }
        }
      )
    },
    getfileClassInfo() {
      this.isSearch = false
      this.fileClassVal = ''
      get('/api/FileAuditType/GetList').then((resp) => {
        if (resp.code == 200) {
          this.fileClassList = resp.data
        }
      })
    },
    cancle() {
      this.isAdd = true
      this.fileNo = ''
      this.fileName = ''
    },
    addFileClass() {
      if (this.fileNo == '' || this.fileName == '') return
      var data = {
        id: this.isAdd ? 0 : this.rowItem.id,
        fileAuditTypeNo: this.fileNo,
        fileAuditTypeName: this.fileName,
        isDelete: 0,
        saveTime: this.isAdd ? new Date().toISOString : this.rowItem.saveTime,
        saveUserId: JSON.parse(sessionStorage.getItem('userInfo')).id + '',
      }
      var methods = this.isAdd ? post : put
      methods('/api/FileAuditType', data)
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: 'success',
              message: this.isAdd ? '添加成功' : '修改成功',
            })
            this.cancle()
            this.getfileClassInfo()
          } else {
            this.$message.error(resp.message)
          }
        })
        .catch(() => {
          this.$message.error(this.isAdd ? '添加失败' : '修改失败')
        })
    },
    deleteItem(row, index) {
      this.$confirm('您确认要删除' + row.fileAuditTypeName + '?')
        .then(() => {
          deleteMethod('/api/FileAuditType?Id=' + row.id)
            .then((resp) => {
              if (resp.code == 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功',
                })
                this.fileClassList.splice(index, 1)
              } else {
                this.$message.error(resp.message)
              }
            })
            .catch(() => {
              this.$message.error('删除失败')
            })
        })
        .catch(() => {})
    },
    editItem(row) {
      this.isAdd = false
      this.rowItem = row
      this.fileName = row.fileAuditTypeName
      this.fileNo = row.fileAuditTypeNo
    },
  },
}
</script>

<style lang="less" scoped>
._Inp {
  width: 240px;
  /deep/ .el-input__inner {
    background-color: #edf0f7 !important;
  }
}

.tow-box {
  display: flex;
  justify-content: center;
  padding-left: 7px;
  > div {
    width: 45%;
    margin-top: 10px;
    padding-left: 25px;
    > p {
      border-left: 5px solid #5a8bff;
      padding-left: 5px;
      margin: 5px 0 15px 0;
    }
    .el-button {
      margin-left: 70px;
      margin-top: 10px;
    }
    .el-button:nth-of-type(1) {
      margin-left: 70px;
      margin-top: 10px;
    }
    .el-button:nth-of-type(2) {
      margin-left: 10px;
      margin-top: 10px;
    }
  }
  /deep/ .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: transparent !important;
  }
  .el-table {
    border: 1px solid #c2c2c2;
    & i {
      border-radius: 50%;
      border: 1px solid silver;
      padding: 5px;
      margin: 0 5px;
    }
    & i:nth-of-type(1) {
      background-color: #fef9f9;
      color: red;
      border: 1px solid #d3b2b2;
    }
    & i:nth-of-type(2) {
      background-color: #eff3fa;
      color: #5a8bff;
      border: 1px solid #b2c7d3;
    }
  }
  .el-table--fit {
    border-right: 1px solid #c2c2c2;
    border-bottom: 1px solid #c2c2c2;
  }
}
._SelectHeader ._Inp {
  width: 300px !important;
  > span {
    width: 180px !important;
  }
}
</style>
